<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">AVISO</h2>
			<p class="text-format-2x text-center px-3">
				<span data-test-id="safari" v-if="safari && !ie">
					In order to perform our onboarding in an effective way we ask you to change your browser
					to Safari. browser to Safari.
				</span>
				<span data-test-id="ie" v-if="!safari && ie">
					It has been detected that you are using Internet Explorer. Please change your browser to
					be able to perform the Onboarding process.
					<p>
						You can download
						<a tabindex="-1" href="https://www.google.com/intl/es_es/chrome/" target="_blank">
							Chrome
						</a>
						,
						<a tabindex="-1" href="https://www.mozilla.org/es-ES/firefox/new/" target="_blank">
							Firefox
						</a>
						o
						<a tabindex="-1" href="https://www.microsoft.com/es-es/edge" target="_blank">
							Microsoft Edge
						</a>
						to continue.
					</p>
				</span>
				<span data-test-id="default" v-if="!safari && !ie">
					In order to perform our onboarding in an effective way we ask you to change your browser.
				</span>
			</p>
			<p class="text-format-2x text-center px-3">Thank you.</p>
		</div>
		<div class="modal-footer justify-content-center"></div>
	</div>
</template>

<script>
export default {
	name: 'm-browser-support',

	props: {
		safari: {
			type: Boolean,
			default: false,
		},
		ie: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
