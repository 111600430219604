import { Unibabel } from 'unibabel';
import service from '@modules/service/m-service';

const algorithmRSA = {
	name: 'RSA-OAEP',
	modulusLength: 2048,
	publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
	hash: {
		name: 'SHA-256',
	},
};

/**
 * Generates a crypto Key pair.
 *
 * @returns {Object} Key
 * @returns {Object} privateKey Raw private key.
 * @returns {String} publicKey Base64 public key encoded.
 */
const generateKeyPair = async () => {
	const keyPair = await crypto.subtle.generateKey(algorithmRSA, true, ['encrypt', 'decrypt']);
	const publicKey = await crypto.subtle.exportKey('spki', keyPair.publicKey);
	const base64PublicKey = Unibabel.bufferToBase64(new Uint8Array(publicKey));

	return {
		privateKey: keyPair.privateKey,
		publicKey: base64PublicKey,
	};
};

/**
 * Decrypt a base64 response.
 * The response it's encrypted with a publicKey.
 *
 * @param {Object} store
 * @param {state} store.state
 * @param {String} data Base64 response.
 * @returns {Object} The payload decrypted and ready to use.
 */
const decryptBase64Response = async (privateKey, data) => {
	const decryptedRes = await crypto.subtle.decrypt(
		algorithmRSA,
		privateKey,
		Unibabel.base64ToArr(data)
	);
	return JSON.parse(Unibabel.bufferToUtf8(new Uint8Array(decryptedRes)));
};

/**
 * Returns the crypto AES Key.
 * @param {Object} store
 * @param {state} store.state
 * @returns {Object} Crypto AES Key.
 */
const importAESKey = async (symmetricKey) => {
	const symmetricKeyBuffer = Unibabel.base64ToArr(symmetricKey).buffer;
	const cryptoKey = await crypto.subtle.importKey(
		'raw',
		symmetricKeyBuffer,
		{ name: 'AES-CBC' },
		true,
		['encrypt', 'decrypt']
	);
	return cryptoKey;
};

const getSecureSession = () => {
	const sessionItem = JSON.parse(sessionStorage.getItem('secureSession')) || {};
	return sessionItem;
};

export default {
	namespaced: true,

	modules: { service },

	actions: {
		/**
		 * Request for a new secured session and register it
		 * on the session storage.
		 */
		async createSession({ dispatch }) {
			const { publicKey, privateKey } = await generateKeyPair();
			return new Promise((resolve, reject) => {
				dispatch('service/generateSession', publicKey)
					.then(async (res) => {
						if (res.status === 200 && res.data) {
							const decryptedBase64Response = await decryptBase64Response(privateKey, res.data);
							dispatch('registerSecureSession', decryptedBase64Response);
							resolve(decryptedBase64Response);
						}
					})
					.catch((res) => {
						reject(res);
					});
			});
		},

		/**
		 * Register a new secured session on the sessionStorage.
		 * @param {Object} sessionData
		 */
		registerSecureSession(context, sessionData) {
			sessionStorage.removeItem('secureSession');
			sessionStorage.setItem('secureSession', JSON.stringify(sessionData));
		},

		/**
		 * Retrieve a secured session from the sessionStorage
		 * and if there isn't generate a new one.
		 */
		retrieveSecureSession({ dispatch }) {
			return new Promise((resolve, reject) => {
				const secureSession = getSecureSession();
				if (secureSession.uuid) {
					return resolve(secureSession);
				}
				dispatch('createSession')
					.then((session) => {
						resolve(session);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		/**
		 * Encrypt data with the symmetric key and returns a base64 string.
		 * @param {store} store
		 * @param {state} store.state
		 * @param {Object} data Data to encrypt
		 * @returns {String} A base64 encrypted data
		 */
		async encrypt(context, data) {
			const { seed, symmetricKey } = getSecureSession();
			const seedBuffer = Unibabel.base64ToArr(seed).buffer;
			const cryptoKey = await importAESKey(symmetricKey);
			const encryptedData = await crypto.subtle.encrypt(
				{
					name: 'AES-CBC',
					iv: seedBuffer,
				},
				cryptoKey,
				Unibabel.utf8ToBuffer(JSON.stringify(data)).buffer
			);

			return Buffer.from(encryptedData).toString('base64');
		},

		/**
		 * Decrypt a base64 string with the symmetric key.
		 * @param {store} store
		 * @param {state} store.state
		 * @param {String} data A base64 string data to decrypt.
		 * @returns {Object} A clean payload.
		 */
		async decrypt(context, data) {
			const { seed, symmetricKey } = getSecureSession();
			const seedBuffer = Unibabel.base64ToArr(seed).buffer;
			const cryptoKey = await importAESKey(symmetricKey);
			const response = await crypto.subtle.decrypt(
				{
					name: 'AES-CBC',
					iv: seedBuffer,
				},
				cryptoKey,
				Unibabel.base64ToBuffer(data).buffer
			);

			return JSON.parse(Unibabel.bufferToUtf8(new Uint8Array(response)));
		},
	},
};
