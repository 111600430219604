import axios from 'axios';
import MErrorHandler from './m-error-handler';

export default {
	namespaced: true,

	modules: { MErrorHandler },

	state() {
		return {
			req: axios.create(),
			baseURL:
				window.VUE_APP_CONFIG.endpoint ||
				process.env.VUE_APP_ENDPOINT ||
				'https://api.grupokukenbank.es',
			timeout: 5000,
		};
	},

	actions: {
		generateSession({ dispatch, state }, publicKey) {
			const config = {
				url: '/key-exchange',
				baseURL: state.baseURL,
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'public-key': publicKey,
				},
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},

		login({ dispatch, state }, { uuid, payload }) {
			const config = {
				url: '/login',
				baseURL: state.baseURL,
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'uuid': uuid,
				},
				data: { payload },
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},

		createOnboardingProcess({ dispatch, state }, { payload, uuid }) {
			const config = {
				url: '/onboarding',
				baseURL: state.baseURL,
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'uuid': uuid,
				},
				data: {
					payload,
				},
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},

		saveOnboardingProcess({ dispatch, state }, { payload, uuid, id }) {
			const config = {
				url: `/onboarding/${id}`,
				baseURL: state.baseURL,
				method: 'patch',
				headers: {
					'Content-Type': 'application/json',
					'uuid': uuid,
				},
				data: {
					payload,
				},
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},

		createEID({ dispatch, state }, { onboardingProcessId, uuid }) {
			const config = {
				url: `/onboarding/${onboardingProcessId}/eid`,
				baseURL: state.baseURL,
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					'uuid': uuid,
				},
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},

		getEIDData({ dispatch, state }, { onboardingProcessId, id, uuid }) {
			const config = {
				url: `/onboarding/${onboardingProcessId}/eid/${id}`,
				baseURL: state.baseURL,
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
					'uuid': uuid,
				},
				timeout: state.timeout,
			};
			return new Promise((resolve, reject) => {
				state
					.req(config)
					.then((res) => {
						resolve(res);
					})
					.catch(async (err) => {
						const error = await dispatch('handleError', err);
						reject(error);
					});
			});
		},
	},
};
