<template>
	<div class="c-spiner">
		<div class="c-spinner-border text-primary" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-spinner',
};
</script>

<style lang="scss" scoped>
.c-spiner {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	justify-content: center;
	align-items: center;
	z-index: 9;
	overflow: hidden;
}
.c-spinner-border {
	width: 9rem;
	height: 9rem;
	vertical-align: text-bottom;
	border: 1.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
	color: $color-primary;
}
</style>
