export default {
	app: {
		state: {
			companyId: 'KB',
			nombreBanco: 'Kukenbank',
			informacionPrecontractual:
				'https://www.kukenbank.es/documents/20486/217345/info_pre.pdf/49baae29-3ffd-430e-90ee-31ba31d9baa4',
			contratoMarco:
				'https://www.kukenbank.es/documents/20486/246998/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+KB.pdf/',
			telephone: '912 34 56 78',
			liveagent: {
				deploymentId: '57209000000kc42',
				orgId: '00D09000007T9DK',
				chatURL: 'https://d.la3-c2-fra.salesforceliveagent.com/chat',
				buttonId: '57309000000kckl',
			},
		},
	},
};
