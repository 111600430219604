import { Server, Model } from 'miragejs';

export default function makeServer({ environment = 'development' } = {}) {
	return new Server({
		environment,

		models: {
			user: Model,
		},

		routes() {
			this.urlPrefix = process.env.VUE_APP_ENDPOINT;
			this.namespace = '';
			this.timing = 400;

			// this.post('api-mocks.grupokukenbank.net/key-exchange', (schema, request) => {
			// 	const attrs = JSON.parse(request.requestBody);
			// 	console.log(attrs);

			// 	return schema.users.create(attrs);
			// });
		},
	});
}
