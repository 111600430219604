<template>
	<svg
		width="64px"
		height="64px"
		viewBox="0 0 64 64"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<polygon
				id="path-1"
				points="0 0.739147541 63.2622164 0.739147541 63.2622164 63.9994754 0 63.9994754"
			/>
		</defs>
		<g id="icn/brand/profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Group-3">
				<mask id="mask-2" fill="white">
					<use xlink:href="#path-1" />
				</mask>
				<g id="Clip-2" />
				<path
					d="M62,32.0004976 C62,48.5700307 48.5704761,62 32.0004975,62 C15.4325091,62 2,48.5700307 2,32.0004976 C2,15.4299693 15.4325091,2 32.0004975,2 C48.5704761,2 62,15.4299693 62,32.0004976"
					id="Fill-1"
					fill="#E1E6EA"
					mask="url(#mask-2)"
				/>
			</g>
			<path
				d="M17.6982,62.2959 L17.6982,46.8019 C17.6982,42.6959 21.0572,39.3369 25.1642,39.3369 L37.1332,39.3369 C41.2392,39.3369 44.5982,42.6959 44.5982,46.8019 L44.5982,62.2959"
				id="Fill-4"
				fill="#040100"
			/>
			<path
				d="M31.1543,11.3662 C25.2313,11.3662 20.4273,16.1672 20.4273,22.0912 C20.4273,28.0142 25.2313,32.8162 31.1543,32.8162 C37.0743,32.8162 41.8693,28.0142 41.8693,22.0912 C41.8693,16.1672 37.0743,11.3662 31.1543,11.3662 M31.1543,14.3662 C35.4083,14.3662 38.8693,17.8312 38.8693,22.0912 C38.8693,26.3502 35.4083,29.8162 31.1543,29.8162 C26.8933,29.8162 23.4273,26.3502 23.4273,22.0912 C23.4273,17.8312 26.8933,14.3662 31.1543,14.3662"
				id="Fill-6"
				fill="#113456"
			/>
			<path
				d="M17.6982,62.2959 L17.6982,46.8019 C17.6982,42.6959 21.0572,39.3369 25.1642,39.3369 L37.1332,39.3369 C41.2392,39.3369 44.5982,42.6959 44.5982,46.8019 L44.5982,62.2959"
				id="Stroke-8"
				stroke="#113456"
				stroke-width="3"
				fill="#000A49"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Stroke-8 {
	fill: $color-primary;
}
</style>
