<template>
	<div id="app">
		<l-main>
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view :inert="inert" />
				</keep-alive>
			</transition>
		</l-main>
		<c-spinner v-if="status" key="spinner" />
		<!-- <c-liveagent /> -->
		<w-modal />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import CSpinner from '@/components/c-spinner';
import WModal from '@/widgets/w-modal';
import LMain from '@layouts/l-main';
import MBrowserSupport from '@modals/m-browser-support';
// import CLiveagent from '@components/c-liveagent';
import DeviceDetector from 'device-detector-js';

export default {
	name: 'App',

	components: { CSpinner, WModal, LMain },

	computed: {
		...mapState('loading', ['status']),

		inert({
			$store: {
				getters: { 'modal/lastOpened': lastModalOpened = false },
			},
		}) {
			return Boolean(lastModalOpened);
		},
	},

	mounted() {
		this.$store.dispatch('liveagent/install');

		/* eslint-disable indent */
		const device = new DeviceDetector().parse(navigator.userAgent);

		switch (true) {
			case device.os.name === 'iOS':
				if (device.client.name !== 'Safari' && device.client.name !== 'Mobile Safari') {
					// Only Safari on iOS is supported
					this.$store.dispatch('modal/open', {
						component: MBrowserSupport,
						props: { safari: true },
					});
				}
				break;

			case device.client.name === 'Internet Explorer':
			case device.client.name === 'Microsoft Edge' && parseFloat(device.client.version) <= 76:
				// IE is not supported
				this.$store.dispatch('modal/open', {
					component: MBrowserSupport,
					props: { ie: true },
				});
				break;

			case device.client.name === 'Mobile Safari' && parseFloat(device.client.version) > 11:
			case device.client.name === 'Safari' && parseFloat(device.client.version) > 11:
			case device.client.name === 'Microsoft Edge' && parseFloat(device.client.version) > 76:
			case device.client.name === 'Chrome' && parseFloat(device.client.version) > 76:
			case device.client.name === 'Chrome Mobile' && parseFloat(device.client.version) > 76:
			case device.client.name === 'Firefox' && parseFloat(device.client.version) > 69:
			case device.client.name === 'Firefox Mobile' && parseFloat(device.client.version) > 63:
			case device.client.name === 'Samsung Browser' && parseFloat(device.client.version) > 11:
				// Supported :)
				break;

			default:
				// Not supported generic message
				this.$store.dispatch('modal/open', MBrowserSupport);
		}
	},
};
</script>

<style lang="scss" scoped>
#app {
	display: block;
	width: 100%;
	height: 100%;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 200ms;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
