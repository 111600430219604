<template>
	<header class="c-header" :class="{ '--is-landing': isLanding }">
		<div class="c-header__container">
			<div class="c-header__logo" />
			<div class="c-header__links-container">
				<router-link class="c-header__link" to="/app-personal-data">Become a customer</router-link>
				<a class="c-header__button-banca" href="https://kukenbank.digitalsmartfi.com/">
					Log in
				</a>
			</div>
			<!-- <div v-if="isLanding" class="c-header__stamp">
				<a
					href="https://www.ocu.org/dinero/cuenta-bancaria/noticias/encuesta-banca"
					target="_blank"
				></a>
			</div>
			<div v-else class="c-header__info">
				<p class="c-header__info-desc1">Estás contratando tu</p>
				<p class="c-header__info-desc2">Cuenta Transparente</p>
			</div> -->
		</div>
	</header>
</template>

<script>
export default {
	name: 'c-header',

	props: {
		isLanding: Boolean,
	},
};
</script>
<style lang="scss" scoped>
.c-header {
	position: fixed;
	font-family: 'Poppins';
	top: 0;
	left: 0;
	right: 0;
	background: $color-primary;
	padding: 10px 0;
	z-index: 10;
}

.c-header__container {
	// max-width: 1374px;
	max-height: 70px;
	min-height: 50px;
	// margin-right: auto;
	// margin-left: auto;
	// padding-left: 100px;
	padding-right: 30px;
}

.c-header__container::before,
.c-header__container::after {
	content: ' ';
	display: table;
}

.c-header.--is-landing .c-header__container {
	justify-content: space-between;
}

.c-header__logo {
	float: left;
	height: 80px;
	width: 200px;
	background-image: url(~@project/assets/img/logo.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-left: 16px;
}

.c-header.--is-landing .c-header__logo {
	width: 300px;
	padding: 10px;
	margin-left: 16px;
	background-image: url(~@project/assets/img/logo.svg);
	background-origin: content-box;
}

// .c-header__stamp {
// 	float: right;
// 	height: 80px;
// 	width: 340px;
// 	background-image: url(~@project/assets/img/sello.svg);
// 	background-repeat: no-repeat;
// 	background-position: center;
// 	background-size: contain;
// 	margin-right: 16px;
// }

// .c-header__stamp a {
// 	display: inline-block;
// 	width: 100%;
// 	height: 100%;
// 	text-decoration: none;
// 	font-size: 0;
// }

.c-header__info {
	margin-top: 1rem;
	margin-right: 16px;
}

.c-header__info-desc1 {
	text-align: right;
	margin: 0;
}

.c-header__info-desc2 {
	color: $color-secondary !important;
	font-family: Poppins, sans-serif !important;
	font-weight: 600;
	font-size: 21px;
	text-align: right;
}
.c-header__links-container {
	height: 80px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 16px;
	margin-left: 100px;
}
.c-header__link {
	font-size: 14px;
	color: $color-secondary;
	text-decoration: none;
	margin-right: 24px;
}
.c-header__button-banca {
	width: 170px;
	height: 50px;
	border-radius: 5px;
	font-size: 14px;
	color: $color-text;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $color-secondary;
	padding: 10px;
}

@media screen and (max-width: 850px) {
	.c-header__container {
		padding: 0;
	}
	.c-header__link {
		display: none;
	}
	.c-header__links-container {
		padding-right: 8px;
	}
}
@media screen and (max-width: 580px) {
	.c-header .c-header__container .c-header__logo,
	.c-header.--is-landing .c-header__container .c-header__logo {
		height: 45px;
		width: 45px;
		background-image: url(~@project/assets/img/logo-single.png);
		padding: 0;
	}

	.c-header__stamp {
		width: 200px;
	}
}

@media screen and (max-device-width: 480px) {
	.c-header__container {
		height: 11%;
	}

	.c-header .c-header__container .c-header__logo,
	.c-header.--is-landing .c-header__container .c-header__logo {
		height: 80px;
		width: 45px;
		background-image: url(~@project/assets/img/logo-single.png);
		padding: 0;
	}

	.c-header__info-desc1 {
		font-size: 10px;
	}
}
</style>
