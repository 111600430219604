import service from '@modules/service/m-service';
import secure from '@modules/secure/m-secure';

export default {
	namespaced: true,

	modules: { service, secure },

	state() {
		return {
			id: '',
			step: 1,
			stepCount: 6,
			nombreCliente: '',
		};
	},

	mutations: {
		SET_ID(state, id) {
			state.id = id;
		},

		INCREASE_STEP(state) {
			state.step += 1;
		},

		SET_NAME(state, name) {
			state.nombreCliente = name;
		},
	},

	actions: {
		createProcess({ commit, dispatch, state }, data) {
			dispatch('loading/start', null, { root: true });

			const initialData = {
				stepCount: state.stepCount,
				step: state.step,
				...data,
			};
			return new Promise((resolve, reject) => {
				dispatch('secure/retrieveSecureSession')
					.then(async (session) => {
						const payload = await dispatch('secure/encrypt', initialData);
						dispatch('service/createOnboardingProcess', {
							uuid: session.uuid,
							payload,
						})
							.then(async (res) => {
								const decryptedData = await dispatch('secure/decrypt', res.data);
								commit('SET_ID', decryptedData.id);
								commit('INCREASE_STEP');
								commit('SET_NAME', initialData.name);
								dispatch('loading/end', null, { root: true });
								resolve(decryptedData);
							})
							.catch((error) => {
								dispatch('loading/end', null, { root: true });
								reject(error);
							});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });
						reject(error);
					});
			});
		},

		saveProcess({ commit, dispatch, state }, data) {
			dispatch('loading/start', null, { root: true });
			const { id, step } = state;
			const initialData = {
				id,
				step,
				...data,
			};
			return new Promise((resolve, reject) => {
				dispatch('secure/retrieveSecureSession')
					.then(async (session) => {
						const payload = await dispatch('secure/encrypt', initialData);
						dispatch('service/saveOnboardingProcess', {
							payload,
							uuid: session.uuid,
							id,
						})
							.then((res) => {
								dispatch('loading/end', null, { root: true });
								commit('INCREASE_STEP');
								resolve(res);
							})
							.catch((error) => {
								dispatch('loading/end', null, { root: true });
								reject(error);
							});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });
						reject(error);
					});
			});
		},
	},
};
