<template>
	<main class="app-landing">
		<div class="app-landing__content">
			<section class="app-landing__section-a">
				<div class="app-landing__titles-container">
					<p class="app-landing__title-a">Welcome to the smart bank.</p>
					<p class="app-landing__title-b">
						Easy, simple and effective.
					</p>
				</div>
			</section>
			<section class="app-landing__section-b">
				<div class="app-landing__section-group">
					<p class="app-landing__text-a">Open your account now</p>
					<ul class="app-landing__ul">
						<li class="app-landing__li">
							<div class="app-landing_check-symbol">
								<span class="app-landing__span" />
							</div>
							<p class="app-landing__li-text">Without conditions or hidden fees</p>
						</li>
						<li class="app-landing__li">
							<div class="app-landing_check-symbol">
								<span class="app-landing__span" />
							</div>
							<p class="app-landing__li-text">
								We make it easy for you
							</p>
						</li>
					</ul>
					<div class="app-landing__button-container">
						<button class="app-landing__button" @click="next">
							Become a customer
						</button>
					</div>
				</div>
			</section>
		</div>
		<footer class="app-landing__footer">
			<p class="app-landing__footer-text">FINANCIAL DISRUPTION</p>
			<div class="app-landing__footer-logo" />
		</footer>
	</main>
</template>

<script>
export default {
	name: 'app-landing',

	methods: {
		next() {
			this.$router.push('app-personal-data');
		},
	},
};
</script>

<style lang="scss" scoped>
.app-landing {
	font-family: 'Poppins';
	width: 100%;
	height: 100%;
	padding-top: 100px;
	padding-bottom: 100px;
}
.app-landing__content {
	width: 100%;
	height: 750px;
	background-image: url(~@project/assets/img/landing.jpg);
	background-position: center;
	background-size: cover;
}
.app-landing__section-a {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 80px 50px 0 200px;
}
.app-landing__titles-container {
	color: $surface-light;
	font-size: 56px;
	font-weight: 600;
}
.app-landing__section-b {
	height: 330px;
	background: $color-primary-light;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 40px 100px 0 250px;
	margin-top: 70px;
}
.app-landing__text-a {
	color: $surface-light;
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}
.app-landing__ul {
	width: 100%;
	color: $surface-light;
	list-style: none;
	padding: 0;
	margin-top: 24px;
}
.app-landing__ul:last-child {
	margin-bottom: 0;
}
.app-landing__li {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}
.app-landing__li-text {
	font-size: 14px;
}
.app-landing_check-symbol {
	width: 8px;
	height: 8px;
	transform: rotate(-45deg);
	margin-right: 16px;
}
.app-landing__span::before {
	content: '';
	width: 1px;
	height: 5px;
	border-radius: 50px;
	display: block;
	background: $surface-light;
}
.app-landing__span::after {
	content: '';
	width: 12.5px;
	height: 1px;
	border-radius: 50px;
	display: block;
	background: $surface-light;
}
.app-landing__button {
	width: 250px;
	height: 50px;
	border-radius: 5px;
	border-style: none;
	background: $color-secondary;
	margin-top: 32px;
}

.app-landing__footer {
	height: 100px;
	background: $surface-light;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 40px;
}
.app-landing__footer-text {
	color: $color-text;
	// font-size: 18px;
	font-weight: 400;
	border-right: solid 1px $color-text;
	padding: 16px 16px 3px 16px;
	margin-right: 16px;
}
.app-landing__footer-logo {
	width: 200px;
	height: 50px;
	background-image: url(~@project/assets/img/logo.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

@media screen and (max-width: 1370px) {
	.app-landing__section-a {
		padding: 80px 50px 0 140px;
	}
	.app-landing__titles-container {
		font-size: 50px;
	}
	.app-landing__section-b {
		padding: 40px 50px 0 190px;
	}
}
@media screen and (max-width: 1100px) {
	.app-landing__section-a {
		padding: 80px 50px 0 100px;
	}
	.app-landing__titles-container {
		font-size: 46px;
	}
	.app-landing__section-b {
		padding: 40px 50px 0 150px;
	}
}
@media screen and (max-width: 950px) {
	.app-landing__section-a {
		padding: 80px 50px 0 50px;
	}
	.app-landing__titles-container {
		font-size: 36px;
	}
	.app-landing__section-b {
		padding: 20px 50px 0 100px;
	}
}
@media screen and (max-width: 750px) {
	.app-landing__titles-container {
		font-size: 30px;
	}
	.app-landing__section-b {
		padding: 20px 50px 0 60px;
	}
	.app-landing__text-a {
		font-size: 18px;
	}
	.app-landing__text-a {
		font-size: 18px;
	}
	.app-landing__li-text {
		font-size: 12px;
	}
	.app-landing__footer {
		padding: 0 30px;
	}
	.app-landing__footer-text {
		text-align: center;
		font-size: 14px;
		padding: 0px 8px 3px 8px;
	}
}
@media screen and (max-width: 450px) {
	.app-landing__section-a {
		padding: 80px 30px 0 30px;
	}
	.app-landing__titles-container {
		font-size: 24px;
	}
	.app-landing__section-b {
		padding: 20px 30px 0 50px;
	}
	.app-landing__text-a {
		font-size: 16px;
	}
}
</style>
