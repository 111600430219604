<template>
	<svg
		width="69px"
		height="72px"
		viewBox="0 0 69 72"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<polygon id="path-1" points="0.4053 0.1462 67.259 0.1462 67.259 67 0.4053 67" />
		</defs>
		<g
			id="icn/brand/document/autorization"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g id="Group-21" transform="translate(1.000000, 4.000000)">
				<g id="Group-3" transform="translate(0.000000, 0.841300)">
					<mask id="mask-2" fill="white">
						<use xlink:href="#path-1" />
					</mask>
					<g id="Clip-2" />
					<path
						d="M0.4053,33.5732 C0.4053,52.0342 15.3713,67.0002 33.8323,67.0002 C52.2933,67.0002 67.2593,52.0342 67.2593,33.5732 C67.2593,15.1122 52.2933,0.1462 33.8323,0.1462 C15.3713,0.1462 0.4053,15.1122 0.4053,33.5732"
						id="Fill-1"
						fill="#E1E6EA"
						mask="url(#mask-2)"
					/>
				</g>
				<path
					d="M9.674,17.34175 L8.231,15.89775 C7.033,14.70075 5.092,14.70075 3.897,15.89775 C2.701,17.09375 2.701,19.03375 3.897,20.23075 L5.342,21.67475"
					id="Stroke-3"
					stroke="#113456"
					stroke-width="2.5"
					fill="#FFFFFF"
					stroke-linejoin="round"
				/>
				<path
					d="M19.0352,20.0273 L19.0352,4.1383 C19.0352,2.5443 20.3282,1.2503 21.9242,1.2503 L53.7012,1.2503 L65.2562,12.8053 L65.2562,59.0273 C65.2562,60.6233 63.9632,61.9163 62.3672,61.9163 L21.9242,61.9163 C20.3282,61.9163 19.0352,60.6233 19.0352,59.0273 L19.0352,43.1383"
					id="Stroke-7"
					stroke="#113456"
					stroke-width="2.5"
					fill="#FFFFFF"
				/>
				<polyline
					id="Stroke-9"
					stroke="#002941"
					stroke-width="2.5"
					points="60.9239 14.25 52.2579 14.25 52.2579 5.583"
				/>
				<path
					d="M46.4786,54.6943 L58.0356,54.6943"
					id="Stroke-11"
					stroke="#000A49"
					stroke-width="2.5"
					stroke-linejoin="round"
				/>
				<path
					d="M29.1465,22.916 L56.5895,22.916"
					id="Stroke-13"
					stroke="#113456"
					stroke-width="2.5"
					stroke-linejoin="round"
				/>
				<path
					d="M29.1465,28.6943 L56.5895,28.6943"
					id="Stroke-15"
					stroke="#113456"
					stroke-width="2.5"
					stroke-linejoin="round"
				/>
				<path
					d="M34.9239,34.4717 L56.5899,34.4717"
					id="Stroke-17"
					stroke="#113456"
					stroke-width="2.5"
					stroke-linejoin="round"
				/>
				<polygon
					id="Stroke-1"
					stroke="#113456"
					stroke-width="2.5"
					fill="#FFFFFF"
					stroke-linejoin="round"
					points="10.3692 17.1386 37.8122 44.5826 42.1462 54.6946 32.0352 50.3606 4.5922 22.9156"
				/>
				<path
					d="M36.3692,44.583 L32.0352,48.916"
					id="Stroke-5"
					stroke="#113456"
					stroke-width="2.5"
					stroke-linejoin="round"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Stroke-11 {
	stroke: $color-primary;
}
</style>
