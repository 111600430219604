const UUID_EXPIRED = 'C401000101';
const UUID_MISSED = 'C4010000';

export default {
	actions: {
		async handleError({ dispatch }, err) {
			if (err && err.code === 'ECONNABORTED') {
				throw new Error(err);
			}

			if (!err.response) {
				return err;
			}

			const { errorCode } = err.response.data;

			if (errorCode === UUID_EXPIRED || errorCode === UUID_MISSED) {
				sessionStorage.removeItem('secureSession');
				dispatch(
					'modal/open',
					{
						component: await import(
							/* webpackChunkName: "chunk-m-expired-session" */ '@modals/m-expired-session'
						),
					},
					{ root: true }
				);
			}

			return err;
		},
	},
};
