import secure from '@modules/secure/m-secure';
import service from '@modules/service/m-service';

export default {
	namespaced: true,

	modules: { secure, service },

	state() {
		return {
			token: '',
			data: {},
			id: '',
		};
	},

	mutations: {
		SET_EID_ID(state, id) {
			state.id = id;
		},

		SET_EID_TOKEN(state, token) {
			state.token = token;
		},

		FILL_EID_DATA(state, data) {
			state.data = data;
		},
	},

	actions: {
		createSession({ commit, dispatch, rootState }) {
			dispatch('loading/start', null, { root: true });

			return new Promise((resolve, reject) => {
				dispatch('secure/retrieveSecureSession')
					.then((session) => {
						dispatch('service/createEID', {
							onboardingProcessId: rootState.onboarding.id,
							uuid: session.uuid,
						})
							.then(async (res) => {
								const decryptedData = await dispatch('secure/decrypt', res.data);
								commit('SET_EID_TOKEN', decryptedData.authorization);
								commit('SET_EID_ID', decryptedData.id);
								dispatch('loading/end', null, { root: true });
								resolve(decryptedData);
							})
							.catch((error) => {
								dispatch('loading/end', null, { root: true });
								reject(error);
							});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });
						reject(error);
					});
			});
		},

		getData({ commit, dispatch, rootState, state }) {
			dispatch('loading/start', null, { root: true });

			return new Promise((resolve, reject) => {
				dispatch('secure/retrieveSecureSession')
					.then((session) => {
						dispatch('service/getEIDData', {
							onboardingProcessId: rootState.onboarding.id,
							id: state.id,
							uuid: session.uuid,
						})
							.then(async (res) => {
								const decryptedData = await dispatch('secure/decrypt', res.data);
								dispatch('loading/end', null, { root: true });
								commit('FILL_EID_DATA', decryptedData);
								resolve(decryptedData);
							})
							.catch((error) => {
								dispatch('loading/end', null, { root: true });
								reject(error);
							});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });
						reject(error);
					});
			});
		},
	},
};
