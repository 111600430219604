import Vue from 'vue';
import './styles.scss';
import Vuelidate from 'vuelidate';
import makeServer from './plugins/server';
import App from './App.vue';
import router from './router';
import bugsnagClient from './plugins/bugsnag';
import store from './store';

const videoIdUrl =
	window.VUE_APP_CONFIG.videoIdUrl || 'https://etrust-sandbox.electronicid.eu/v2/js/videoid.js';
const script = window.document.createElement('script');
script.type = 'text/javascript';
script.src = videoIdUrl;

window.document.head.appendChild(script);

if (process.env.VUE_APP_ENDPOINT_MODE === 'mck') {
	makeServer();
}

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// If running inside Cypress...
if (window.Cypress) {
	// Ensure tests fail when Vue emits an error.
	Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;
}

Vue.use(Vuelidate);

const instance = new Vue({
	router,
	store,
	render: (h) => h(App),
	created() {
		this.$store.dispatch('bugsnag/install', bugsnagClient);
		sessionStorage.removeItem('secureSession');
	},
	mounted() {
		this.$router.replace('/');
	},
}).$mount('#app');

window.__app__ = instance; // eslint-disable-line no-underscore-dangle
