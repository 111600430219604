<template>
	<svg
		width="68px"
		height="68px"
		viewBox="0 0 68 68"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<polygon id="path-1" points="0.5845 0.147 68.5845 0.147 68.5845 68.147 0.5845 68.147" />
		</defs>
		<g id="icn/brand/biometry" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Group-6" transform="translate(-1.000000, 0.000000)">
				<mask id="mask-2" fill="white">
					<use xlink:href="#path-1" />
				</mask>
				<g id="Clip-5" />
				<path
					d="M35,68 C53.7774554,68 69,52.7774554 69,34 C69,15.2225446 53.7774554,0 35,0 C16.2225446,0 1,15.2225446 1,34 C1,52.7774554 16.2225446,68 35,68 Z"
					id="Fill-4"
					fill="#8798AA"
					opacity="0.239999995"
					mask="url(#mask-2)"
				/>
			</g>
			<g id="Group-45" transform="translate(24.000000, 43.000000)">
				<path
					d="M0.5967,13.1128 L0.5967,5.3838 C0.5967,2.4648 2.9847,0.0768 5.9037,0.0768 L14.4107,0.0768 C17.3297,0.0768 19.7177,2.4648 19.7177,5.3838 L19.7177,13.1128"
					id="Fill-42"
					fill="#000A49"
				/>
				<path
					d="M0.5967,13.1128 L0.5967,5.3838 C0.5967,2.4648 2.9847,0.0768 5.9037,0.0768 L14.4107,0.0768 C17.3297,0.0768 19.7177,2.4648 19.7177,5.3838 L19.7177,13.1128"
					id="Stroke-44"
					stroke="#113456"
					stroke-width="3"
					stroke-linejoin="round"
				/>
			</g>
			<path
				d="M21.0059,15.0132 L18.8979,15.0132 C17.1559,15.0132 15.7339,16.4362 15.7339,18.1772 L15.7339,19.2492"
				id="Stroke-46"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M15.7334,43.5737 L15.7334,44.6457 C15.7334,46.3867 17.1564,47.8097 18.8974,47.8097 L21.0054,47.8097"
				id="Stroke-48"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M47.3086,47.8101 L49.4186,47.8101 C51.1586,47.8101 52.5826,46.3871 52.5826,44.6461 L52.5826,43.5741"
				id="Stroke-50"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M52.583,19.2495 L52.583,18.1775 C52.583,16.4365 51.158,15.0135 49.419,15.0135 L47.309,15.0135"
				id="Stroke-52"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M39.7588,15.0132 L28.5578,15.0132"
				id="Stroke-54"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M52.583,37.0122 L52.583,25.8112"
				id="Stroke-56"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M15.7334,37.0122 L15.7334,25.8112"
				id="Stroke-58"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M34.1573,15.0132 L34.1573,10.8452"
				id="Stroke-60"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M52.583,31.4116 L57.393,31.4116"
				id="Stroke-62"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M10.9239,31.4116 L15.7339,31.4116"
				id="Stroke-64"
				stroke="#113456"
				stroke-width="3"
				stroke-linejoin="round"
			/>
			<path
				d="M33.625,22 C29.415,22 26,25.413 26,29.624 C26,33.835 29.415,37.248 33.625,37.248 C37.834,37.248 41.242,33.835 41.242,29.624 C41.242,25.413 37.834,22 33.625,22"
				id="Path"
				fill="#113456"
			/>
			<path
				d="M33.625,25 C36.171,25 38.242,27.074 38.242,29.624 C38.242,32.173 36.171,34.248 33.625,34.248 C31.075,34.248 29,32.173 29,29.624 C29,27.074 31.075,25 33.625,25"
				id="Path"
				fill="#CFD6DD"
			/>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Fill-42 {
	fill: $color-primary;
}
</style>
