<template>
	<svg
		width="74px"
		height="68px"
		viewBox="0 0 74 68"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<polygon id="path-1" points="0.4599 0.147 67.313 0.147 67.313 67 0.4599 67" />
			<polygon id="path-3" points="0 67.8535 73.313 67.8535 73.313 1.0005 0 1.0005" />
		</defs>
		<g id="icn/brand/briefcase" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Group-14">
				<g id="Group-3" transform="translate(6.000000, 0.853500)">
					<mask id="mask-2" fill="white">
						<use xlink:href="#path-1" />
					</mask>
					<g id="Clip-2" />
					<path
						d="M0.4599,33.5733 C0.4599,52.0343 15.4259,67.0003 33.8869,67.0003 C52.3479,67.0003 67.3139,52.0343 67.3139,33.5733 C67.3139,15.1123 52.3479,0.1463 33.8869,0.1463 C15.4259,0.1463 0.4599,15.1123 0.4599,33.5733"
						id="Fill-1"
						fill="#E1E6EA"
						mask="url(#mask-2)"
					/>
				</g>
				<path
					d="M20.6367,14.1035 L20.6367,10.0005 C20.6367,8.3225 21.9867,6.9725 23.6367,6.9725 L36.8867,6.9725 C38.5367,6.9725 39.8867,8.3225 39.8867,10.0005 L39.8867,14.1035"
					id="Stroke-4"
					stroke="#113456"
					stroke-width="3"
					stroke-linejoin="round"
				/>
				<path
					d="M59.0175,38.9521 L59.0175,55.3381 C59.0175,56.9871 57.6675,58.3381 56.0175,58.3381 L4.5055,58.3381 C2.8555,58.3381 1.5055,56.9871 1.5055,55.3381 L1.5055,38.9521"
					id="Stroke-6"
					stroke="#113456"
					stroke-width="3"
					stroke-linejoin="round"
				/>
				<g id="Group-11" transform="translate(1.000000, 17.853500)">
					<path
						d="M33.0175,16.334 L58.0235,16.334 L58.0235,3.635 C58.0235,1.984 56.6735,0.635 55.0235,0.635 L3.4995,0.635 C1.8495,0.635 0.4995,1.984 0.4995,3.635 L0.4995,16.334 L25.5055,16.334"
						id="Fill-8"
						fill="#000A49"
					/>
					<path
						d="M33.0175,16.334 L58.0235,16.334 L58.0235,3.635 C58.0235,1.984 56.6735,0.635 55.0235,0.635 L3.4995,0.635 C1.8495,0.635 0.4995,1.984 0.4995,3.635 L0.4995,16.334 L25.5055,16.334"
						id="Stroke-10"
						stroke="#113456"
						stroke-width="3"
					/>
				</g>
				<mask id="mask-4" fill="white">
					<use xlink:href="#path-3" />
				</mask>
				<g id="Clip-13" />
				<polygon
					id="Stroke-12"
					stroke="#113456"
					stroke-width="3"
					mask="url(#mask-4)"
					points="26.512 40.3635 34.012 40.3635 34.012 27.8635 26.512 27.8635"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#Fill-8 {
	fill: $color-primary;
}
</style>
