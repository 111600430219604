import Vue from 'vue';
import VueRouter from 'vue-router';
import AppLanding from '@/steps/app-landing-new';
import AppChooseOptions from '@/steps/app-choose-options';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'app-landing',
		component: AppLanding,
		// component: () =>
		// 	import(
		// 		/* webpackChunkName: "app-camera-capture", webpackPrefetch: true */ '@/steps/app-camera-capture'
		// 	),
	},
	{
		path: '/app-personal-data',
		name: 'app-personal-data',
		component: () =>
			import(
				/* webpackChunkName: "app-personal-data", webpackPrefetch: true */ '@/steps/app-personal-data'
			),
	},
	{
		path: '/app-choose-options',
		name: 'app-choose-options',
		component: AppChooseOptions,
	},
	{
		path: '/app-address',
		name: 'app-address',
		component: () =>
			import(/* webpackChunkName: "app-address", webpackPrefetch: true */ '@/steps/app-address'),
	},
	{
		path: '/app-confirmation',
		name: 'app-confirmation',
		component: () =>
			import(
				/* webpackChunkName: "app-confirmation", webpackPrefetch: true */ '@/steps/app-confirmation'
			),
	},
	{
		path: '/app-camera-capture',
		name: 'app-camera-capture',
		component: () =>
			import(
				/* webpackChunkName: "app-camera-capture", webpackPrefetch: true */ '@/steps/app-camera-capture'
			),
	},
	{
		path: '/app-professional-data',
		name: 'app-professional-data',
		component: () =>
			import(
				/* webpackChunkName: "app-professional-data", webpackPrefetch: true */ '@/steps/app-professional-data'
			),
	},
];

const router = new VueRouter({
	mode: 'abstract',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

export default router;
