<template>
	<div id="app-choose-options">
		<app-stepper :step-number="4"></app-stepper>
		<div>
			<div class="row flex-column align-items-center mainBox">
				<h2 class="mx-md-4 mx-3 mb-5 text-center text-format-1">
					{{ $store.state.onboarding.nombreCliente }} Finally, we are going to confirm your identity
					through a video-identification process, but first we need your consent.
				</h2>
				<form
					class="d-flex flex-column align-items-center ng-untouched ng-pristine ng-invalid"
					ngxsform="wizard.forms.3"
					novalidate
				>
					<div class="d-flex flex-column justify-content-center mb-5">
						<div class="d-flex justify-content-center space-error mb-3">
							<span v-if="error" class="text-center text-danger">
								To continue you must authorize the processing of your biometric data.
							</span>
						</div>
						<div class="container px-sm-5 d-flex justify-content-center pl-4 pr-4">
							<label class="containerBox">
								<input type="checkbox" v-model="authorizesBiometricDataTreatmentAndMaintenance" />
								<span class="checkmarkEX"></span>
								<p class="block m-0 tf text-justify">
									I authorize the processing of my biometric data with the purpose of verifying my
									identity, and the conservation of the process during the legally established
									period according to the Money Laundering Prevention regulations.
								</p>
							</label>
						</div>
					</div>
					<div class="confirm-button-grouo">
						<button
							data-test-id="confirm-button"
							class="button-confirm mb-5"
							type="submit"
							@click.prevent="submit"
						>
							NEXT
						</button>
					</div>
					<app-mobile-call></app-mobile-call>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';
import AppMobileCall from '@/components/app-mobile-call';

export default {
	name: 'app-choose-options',

	components: {
		AppStepper,
		AppMobileCall,
	},

	data() {
		return {
			error: false,
			authorizesBiometricDataTreatmentAndMaintenance: false,
		};
	},

	methods: {
		submit() {
			// const { authorizesBiometricDataTreatmentAndMaintenance } = this;
			// this.error = !authorizesBiometricDataTreatmentAndMaintenance;

			// if (!this.error) {
			// 	this.$store
			// 		.dispatch('onboarding/saveProcess', {
			// 			authorizesBiometricDataTreatmentAndMaintenance,
			// 		})
			// 		.then(() => {
			// 			this.getEIDToken();
			// 		});
			// }
			this.$router.push('app-camera-capture');
		},

		getEIDToken() {
			// this.$store
			// 	.dispatch('eid/createSession')
			// 	.then(() => {
			// 		this.$router.push('app-camera-capture');
			// 	})
			// 	.catch((error) => {
			// 		throw new Error(error);
			// 	});
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 50px 0;
}
.containerBox {
	margin-top: 1px;
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
}
.containerBox p {
	font-size: 14px;
}
.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmarkEX {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border: 2px solid #113456;
}
.containerBox input:checked ~ .checkmarkEX {
	background-color: #fff;
}
.checkmarkEX:after {
	content: '';
	position: absolute;
	display: none;
}
.containerBox input:checked ~ .checkmarkEX:after {
	display: block;
}
.containerBox .checkmarkEX:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid #113456;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	img {
		width: 14px;
	}
	tbody {
		width: 90%;
	}
	td {
		padding-left: 0;
	}
}
@media screen and (max-width: 580px) {
	.button-confirm {
		width: 300px !important;
	}
}
</style>
