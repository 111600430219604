<template>
	<div>
		<div _ngcontent-sww-c12 class="mx-4 mb-5 blockCall">
			<div _ngcontent-sww-c12 class="d-flex callHelp align-items-center">
				<img _ngcontent-sww-c12 alt src="@/assets/images/call.svg" />
				<p _ngcontent-sww-c12>
					Call us at
					<span _ngcontent-sww-c12 class="tfLinkKB">{{ telephone }},</span>
					Call us if you have any questions.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'app-mobile-call',

	computed: {
		telephone({ $store }) {
			return $store.state.app.telephone;
		},
	},
};
</script>

<style lang="scss" scoped>
.callHelp {
	height: 30px;
}
.callHelp img {
	width: 32px;
	height: 26px;
	margin-right: 10px;
}

@media screen and (min-width: 480px) {
	.blockCall {
		display: none;
	}
}
</style>
