import secure from '@modules/secure/m-secure';
import service from '@modules/service/m-service';

export default {
	namespaced: true,

	modules: { secure, service },

	actions: {
		login({ dispatch, rootState }) {
			dispatch('loading/start', null, { root: true });

			const credentials = {
				companyId: rootState.app.companyId,
				channel: 'WEB',
				deviceId: rootState.device.id,
			};

			return new Promise((resolve, reject) => {
				dispatch('secure/retrieveSecureSession')
					.then(async (session) => {
						const payload = await dispatch('secure/encrypt', credentials);
						dispatch('service/login', {
							uuid: session.uuid,
							payload,
						})
							.then((res) => {
								if (res.status === 200) {
									resolve(true);
								}
							})
							.catch((res) => {
								dispatch('loading/end', null, { root: true });
								reject(res);
							});
					})
					.catch((err) => {
						dispatch('loading/end', null, { root: true });
						reject(err);
					});
			});
		},
	},
};
